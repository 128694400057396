import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




const LineChart = ({ borderColor = 'rgb(75, 192, 192)' }) => {


  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [20, 145, 32, 20, 59, 80, 81, 56, 55, 56, 10, 2, 89, 63,],
        borderColor: borderColor,
        tension: 0.1,
      }
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false, // Hide the dataset label
      },
    },
  };

  return (
    <div>
      <Line
        data={data}
        options={options}
      />
    </div>
  )
}

export default LineChart