import { Card, Col, Row, Tabs, Typography, Radio, Skeleton } from "antd";
import React, { useEffect, useState, } from "react";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import ProgressBar from "../../components/ProgressBar";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
const { TabPane } = Tabs;
const { Title, Text } = Typography;

const DriversGraph = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [period, setPriod] = useState('weekly')
    const [total, setTotal] = useState(0)
    const [selectedTab, setSelectedTab] = useState('country')
    const { request } = useRequest();
    const { userProfile } = useAuthContext()

    useEffect(() => {

        if (!userProfile) return

        if (userProfile.type == 'SubAdmin') {
            if (!userProfile.permission.includes('dashboard-management')) return
        }

        request({
            url: apiPath.dashboard + `/acquire-drivers/${period}?type=${selectedTab}`,
            method: "GET",
            onSuccess: ({ data }) => {
                setLoading(false);
                const { total, totalCount } = data
                setData(total);
                setTotal(totalCount)
            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    }, [period, selectedTab, userProfile]);

    return (
        <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
                <div className="graph-title">
                    <Title level={5}>{lang("Drivers")}</Title>
                    <Row style={{ justifyContent: "end" }}>
                        <Radio.Group value={period} onChange={({ target: { value } }) => {
                            console.log('radio3 checked', value);
                            setPriod(value);
                        }} defaultValue="month" buttonStyle="solid">
                            <Radio.Button value="weekly">
                                {lang("Week")}
                            </Radio.Button>
                            <Radio.Button value="monthly">
                                {lang("Month")}
                            </Radio.Button>
                            <Radio.Button value="yearly">
                                {lang("Year")}
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                </div>
                <Tabs
                    className="main_tabs"
                    onChange={(key) => setSelectedTab(key)}
                    activeKey={selectedTab}
                    tabBarStyle={{ color: "green" }}
                >
                    <TabPane tab={<div className="tab_title"><span>{total}</span> <span>{lang("country")}</span></div>} key="country">
                        <div className="graph_inner_title">
                            <h3>{lang("Country")}</h3>
                            <h3>{lang("Drivers")}</h3>
                        </div>
                        {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />)
                            :
                            data.map((item, index) => <ProgressBar key={index} count={item.count} name={item._id.name} total={total} />)
                        }
                    </TabPane>
                    <TabPane tab={
                        <div className="tab_title"><span>{total}</span> <span>{lang('City')}</span></div>} key="city">
                        <div className="graph_inner_title">
                            <h3>{lang("Cities")}</h3>
                            <h3>{lang("Drivers")}</h3>
                        </div>
                        {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />)
                            :
                            data.map((item, index) => <ProgressBar key={index} count={item.count} name={item._id.name} total={total} />)
                        }
                    </TabPane>
                </Tabs>
            </Card>
        </Col>
    )
}

export default DriversGraph