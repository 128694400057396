import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Rate,
  Row,
  Switch,
  TimePicker,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import starLight from "../../assets/images/icon/starLight.png";
import starLineIcon from "../../assets/images/icon/starLineIcon.png";
import notfound from "../../assets/images/not_found.png";
import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { Palestine } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import UploadImage from "../User/Customer/_UploadImage";
import LocationMap from "../User/LocationMap";
import MultipleImageUpload from "../../components/MultipleImageUpload2";
import checkNumber from "../../helper/apis";

const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const ViewAndEditModal = ({ show, hide, data, refresh, deleteRestaurant }) => {
  const api = {
    restaurant: apiPath.restaurant,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const { country } = useAppContext();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [isFeature, setIsFeature] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [areas, setAreas] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [document, setDocument] = useState();
  const [logo, setLogo] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [location, setLocation] = useState(null);
  const [editLocation, setEditLocation] = useState(null);

  //for edit
  const [deleteModal, showDeleteModal] = useState(false);
  const { language } = useAppContext();

  const getAreas = (id) => {
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          const ar_areas = data.map((item) => {
            return { ...item, name: item.ar_name };
          });
          setAreas(language === "en" ? data : ar_areas);
        }
      },
    });
  };

  const getCategory = (country_id) => {
    request({
      url: api.rc + `?country_id=${country_id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          const ar_category = data.map((item) => {
            return { ...item, name: item.ar_name };
          });
          setRestaurantCategories(language === "en" ? data : ar_category);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    //getCountry();
    // getCities();
    // getCategory();
    // getFoodCategory();
  }, []);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          const ar_cities = data.map((item) => {
            return { ...item, name: item.ar_name };
          });
          setCities(language === "en" ? data : ar_cities);
        }
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    const { location, ...rest } = data;
    console.log(data, "Edit");
    form.setFieldsValue({
      ...rest,
      close_time: moment(rest.close_time),
      open_time: moment(rest.open_time),
      time: [moment(rest.open_time), moment(rest.close_time)],
      mobile: rest.country_code + rest.mobile_number,
      mobile2: rest?.vendor_id?.country_code_sec
        ? rest?.vendor_id?.country_code_sec + rest?.vendor_id?.mobile_number_sec
        : "",
      country_id: rest?.country_id?._id,
      city_id: rest?.city_id?._id,
      area: rest?.area?._id,
      category_id: rest?.category_id,
      location_on_map: rest.address,
      have_whatsapp: rest?.vendor_id?.have_whatsapp,
      have_whatsapp_sec: rest?.vendor_id?.have_whatsapp_sec,
    });
    setIsFeature(rest.is_featured);
    setLogo(rest.logo);
    setImage(rest.image);
    console.log(rest.image, "Image");
    setLocation({
      map_address: rest.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    setEditLocation({
      map_address: rest.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    getCities(rest?.country_id?._id);
    getAreas(rest?.city_id?._id);
    getCategory(rest?.country_id?._id);
    setMobileNumber({
      country_code: rest.country_code,
      mobile_number: rest.mobile_number,
    });

    setMobileNumber2({
      country_code_sec: rest.country_code_sec,
      mobile_number_sec: rest.mobile_number_sec,
    });

    setDocument(rest.document);
  }, [data]);

  const onCreate = async (values) => {
    setLoading(true);
    // const valid = await checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
    // if (!valid) return setLoading(false);

    const checks = [
      checkNumber({
        number: mobileNumber.country_code + mobileNumber.mobile_number,
      }),
    ];

    if (mobileNumber2?.mobile_number_sec?.length > 4) {
      checks.push(
        checkNumber({
          number:
            mobileNumber2.country_code_sec + mobileNumber2.mobile_number_sec,
        })
      );
    }

    const results = await Promise.all(checks);

    if (results.some((valid) => !valid)) {
      return setLoading(false);
    }

    const { location_on_map, time, area } = values;
    let payload = {
      ...values,
      ...mobileNumber,
      open_time: time[0],
      close_time: time[1],
      ...mobileNumber2,
      ...location,
      // image: image,
      image: image,
      country_id: country.country_id,
      area,
    };
    setLoading(true);
    if (logo) payload.logo = logo;
    if (document) payload.document = document;
    request({
      url: `${api.restaurant}/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLegal = (data) => {
    setDocument(data[0]?.url);
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  const handleMobileNumberChange2 = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber2({
      country_code_sec: country_code,
      mobile_number_sec: value.slice(data?.dialCode?.length),
    });
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const dummyDeliveryTypes = [
    { name: "Delivery", ar_name: "توصيل", _id: "Delivery" },
    { name: "Pickup", ar_name: "استلام شخصي", _id: "Pickup" },
  ];
  const handleLocationChange = (value) => {
    console.log(value, "valuevaluevalue");
    setLocation({
      map_address: value.map_address,
      latitude: value.lat ?? value?.latitude,
      longitude: value.lng ?? value?.longitude,
    });
  };

  const feature = (id) => {
    request({
      url: `${api.restaurant}/${id}/feature`,
      method: "PUT",
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setIsFeature((prev) => !prev);
        refresh();
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  return (
    <Modal
      open={show}
      width={1400}
      okText={lang("Save")}
      footer={null}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form
        initialValues={{
          have_whatsapp_sec: false,
          have_whatsapp: false,
        }}
        id="create"
        form={form}
        // disabled={!isEdit}
        onFinish={onCreate}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Row gutter={[16]}>
              <Col span={24} lg={24} xs={24}>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <h4 className="modal_title_cls mb-2 modal_title_menu text-start">
                      {data.name}
                    </h4>
                    <div className="restaurantReview d-flex align-items-center gap-2  ">
                      <div className="d-flex align-items-center gap-2 lh-1">
                        <Rate
                          disabled
                          value={data?.rating ? data?.rating : 0}
                        />
                      </div>
                      <div className="reviewTxt">
                        <b>
                          {data?.rating
                            ? parseFloat(data?.rating).toFixed(1)
                            : 0}
                        </b>
                        {" (" +
                          (data?.review_count ? data?.review_count : 0) +
                          `(${lang("Reviews")}))`}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 d-flex align-items-center gap-3 justify-content-end">
                    <Button
                      onClick={() => setIsEdit(true)}
                      className="edit-cls btnStyle primary_btn   d-flex align-items-center"
                    >
                      <img src={EditIcon} />
                      {lang("Edit")}
                    </Button>

                    <Button
                      onClick={deleteRestaurant}
                      className="edit-cls btnStyle deleteDangerbtn py-2 px-3 d-flex align-items-center gap-1"
                    >
                      <img src={deleteWhiteIcon} />
                      {lang("Delete")}{" "}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col span={24} xs={24}>
                <div className="d-flex align-items-center   gap-2 fs-6 fw-medium">
                  {lang("Mark as Featured")}

                  <img
                    onClick={() => feature(data._id)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isFeature ? "yellow" : "",
                    }}
                    src={starLineIcon}
                    alt=""
                  />
                </div>
              </Col>

              <Col span={24} lg={6} xs={24}>
                <Card className="">
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (logo) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Profile image is required")
                              );
                            },
                          },
                        ]}
                        name="logo"
                      >
                        <UploadImage
                          disabled={!isEdit}
                          value={logo}
                          setImage={setLogo}
                        />
                      </Form.Item>
                    </Col>
                    <SelectInput
                      disabled={!isEdit}
                      mode="multiple"
                      className="w-100 restaurant-selected"
                      cover={{ md: 24 }}
                      name="category_id"
                      label={lang("Restaurant Category")}
                      placeholder="Select Category"
                      options={restaurantCategories}
                      rules={[
                        {
                          required: true,
                          message: lang("Missing Type Selection"),
                        },
                      ]}
                    />
                  </div>
                </Card>
              </Col>

              <Col span={24} lg={6} xs={24} className="mb-2">
                <Card className="" style={{ height: "100%" }}>
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap img-uploaded"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value !== undefined && value?.length > 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(lang("Profile image is required"))
                              );
                            },
                          },
                        ]}
                        label={lang("Upload Image")}
                        name="image"
                      >
                        <MultipleImageUpload
                          disabled={!isEdit}
                          value={image}
                          data={image}
                          fileType={FileType}
                          imageType={"category"}
                          btnName={"Image"}
                          isDimension={true}
                          onChange={(data) => handleImage(data)}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col span={24} lg={14} xs={24} className="d-flex flex-column gap-3">
            <Card className="">
              <div className="row g-3">
                <TextInputBox
                  isDisable={!isEdit}
                  name="name"
                  label={lang("Restaurant’s Name")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />

                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_name"
                  label={lang("Restaurant’s Name Arabic")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="description"
                  label={lang("Restaurant Description")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_description"
                  label={lang("Restaurant Description Arabic")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <div className="col-xxl-6 col-lg-12 col-md-6">
                  <Form.Item
                    className="checkBox_wrap"
                    label={lang("Working Days")}
                    name="working_days"
                  >
                    <Checkbox.Group
                      disabled={!isEdit}
                      onChange={(value) => console.log(value, "working")}
                    >
                      {weekdays.map((item, idx) => (
                        <Checkbox value={item.name} key={idx}>
                          {" "}
                          {item.label}{" "}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12 col-md-6 px-0">
                  <Row gutter={20}>
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Working Time")}
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select working time"),
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          disabled={!isEdit}
                          format="h:mm a"
                          placeholder={[lang("Open Time"), lang("Close Time")]}
                          use12Hours
                          order={false}
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col span={24} md={12}>
                                            <Form.Item
                                                label="Close Time"
                                                name="close_time"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select close time",
                                                    }]}
                                            >
                                                <TimePicker
                                                    format="HH:mm A"
                                                    use12Hours
                                                    disabled={!isEdit}

                                                />
                                            </Form.Item>
                                        </Col> */}
                  </Row>
                </div>
              </div>
            </Card>
            <Card>
              <Row gutter={[16, 16]} className="g-3">
                {/* <SelectInput
                                    disabled={!isEdit}
                                    name="country_id"
                                    label="Country"
                                    placeholder="Select country"
                                    options={countries}
                                    handleChange={(value) => {
                                        getCities(value)
                                        getCategory(value)
                                        form.setFieldsValue({ category_id: '' })
                                    }}
                                    showSearch
                                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    cover={{ md: 5 }}
                                    rules={[
                                        { required: true, message: "Missing Type Selection" },
                                    ]}
                                /> */}

                <SelectInput
                  disabled={!isEdit}
                  name="city_id"
                  label={lang("City")}
                  placeholder={lang("Select City")}
                  showSearch
                  handleChange={(value) => {
                    getAreas(value);
                    form.setFieldsValue({ area: null });
                  }}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities}
                  cover={{ md: 5 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                />

                {country.country_id == Palestine.id && (
                  <SelectInput
                    disabled={!isEdit}
                    name="area"
                    label={lang("Area")}
                    placeholder={lang("Select Area")}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={areas} //TODO:  change to area
                    /**
                                     *  options={areas.map(item=>({
                                      _id:item.name,
                                      name:item.name
                                    }))} 
                                     */
                    cover={{ md: 12 }}
                    colProps={{ sm: 24, span: 24 }}
                    rules={[
                      {
                        required: country.country_id == Palestine.id,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  />
                )}

                {/* <TextInputBox
                                    isDisable={!isEdit}
                                    name="area"
                                    label="Area"
                                    placeholder="Enter Area"
                                    rules={[
                                        {
                                            max: 20,
                                            message:
                                                "Area should contain more then 20 characters!",
                                        },

                                    ]}
                                /> */}

                <TextInputBox
                  isDisable={!isEdit}
                  name="address"
                  label={lang("Address")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Address should contain more then 600 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_address"
                  label={lang("Address Arabic")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Address should contain more then 600 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
              </Row>
            </Card>
            {/* <Card className="">
                            <div className="row g-3">
                                <SelectInput className="w-100"
                                    name="category_id" 
                                    mode="multiple"
                                    label="Restaurant Category"
                                    placeholder="Select Category"
                                    options={restaurantCategories}
                                    rules={[
                                        { required: true, message: "Missing Type Selection" },
                                    ]}
                                />
                                <div className="col-md-6">
                                    <Form.Item className="mb-0"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (document !== undefined && document?.length > 0) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error("legal documents is required"),
                                                    );
                                                },
                                            },
                                        ]}
                                        disabled={!isEdit}
                                        label="Upload legal documents "
                                        name="document1"
                                        placeholder="Upload Logo"
                                    >
                                        <SingleImageUpload
                                            disabled={!isEdit}
                                            value={document}
                                            fileType={FileType}
                                            imageType={"legal"}
                                            onChange={(data) => handleUploadLegal(data)}
                                        />
                                        {document && document.length > 0 && (
                                            <div className="mt-2">
                                                {" "}
                                                <Image
                                                    width={120}
                                                    src={document !== "" ? document : notfound}
                                                ></Image>{" "}
                                            </div>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                        </Card> */}
            <Row gutter={[16, 16]}>
              <Col md={12}>
                <Card>
                  <Row gutter={[0, 16]} className="g-3">
                    <TextInputBox
                      isDisable={!isEdit}
                      name="contact_person_name"
                      label={lang("Contact Person Name")}
                      placeholder={lang("Enter Contact Person Name")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          max: 20,
                          message: lang(
                            "Name should contain more then 20 characters!"
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter Person Name"),
                        },
                      ]}
                    />

                    <Col span={24} sm={24}>
                      <Form.Item
                        label={lang("Email ID")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: lang(
                              "Please enter a valid email address!"
                            ),
                          },
                          {
                            max: 50,
                            message: lang(
                              "Email address not more then 255 characters!"
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter email!"),
                          },
                        ]}
                      >
                        <Input
                          disabled={!isEdit}
                          placeholder={lang("Enter Email ID")}
                          colProps={{ sm: 24, span: 24 }}
                        />
                      </Form.Item>
                    </Col>

                    <PhoneNumberField
                      inputProps={{
                        disabled: !isEdit,
                      }}
                      label={lang("Contact Person Phone Number")}
                      name="mobile"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange}
                    />

                    <Form.Item name="have_whatsapp" valuePropName="checked">
                      <Checkbox className="">
                        {lang("This number have WhatsApp to receive messages?")}
                      </Checkbox>
                      {/* //<span className="toggle_title">Risk of Waste</span> */}
                    </Form.Item>

                    <PhoneNumberField
                      label={lang("Contact Person Phone Number")}
                      name="mobile2"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange2}
                      rules={false}
                    />

                    <Form.Item name="have_whatsapp_sec" valuePropName="checked">
                      <Checkbox className="">
                        {lang("This number have WhatsApp to receive messages?")}
                      </Checkbox>
                      {/* //<span className="toggle_title">Risk of Waste</span> */}
                    </Form.Item>
                  </Row>
                </Card>
              </Col>
              <Col span={24} md={12}>
                <Card>
                  <Row gutter={[16, 16]} className="g-3">
                    <TextInputBox
                      isDisable={!isEdit}
                      name="business_id"
                      label={lang("Business Identification Number")}
                      placeholder={"Enter Business Identification Number"}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        // {
                        //     max: 16,
                        //     message: "Please Enter 16 digit BIN!",
                        // },
                        {
                          required: true,
                          message: lang("Enter Business Identification Number"),
                        },
                      ]}
                    />
                    <div className="col-12">
                      <Form.Item
                        className="mb-0"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (
                                document !== undefined &&
                                document?.length > 0
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(lang("legal documents is required"))
                              );
                            },
                          },
                        ]}
                        disabled={!isEdit}
                        label={lang("Upload legal documents")}
                        name="document1"
                        placeholder={lang("Upload Logo")}
                      >
                        <SingleImageUpload
                          disabled={!isEdit}
                          value={document}
                          fileType={FileType}
                          imageType={"legal"}
                          onChange={(data) => handleUploadLegal(data)}
                        />
                        {document && document.length > 0 && (
                          <div className="mt-2">
                            {" "}
                            <Image
                              width={120}
                              src={document !== "" ? document : notfound}
                            ></Image>{" "}
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={10} xs={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <SelectInput
                  disabled={!isEdit}
                  mode="multiple"
                  name="delivery_type"
                  label={lang("Delivery Type")}
                  className="restaurant-selected"
                  placeholder={lang("Select Delivery Type")}
                  options={
                    language == "en"
                      ? dummyDeliveryTypes
                      : dummyDeliveryTypes.map((item) => {
                          return { ...item, name: item.ar_name };
                        })
                  }
                  cover={{ md: 12 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                />
                <Col span={24} md={12}>
                  <Form.Item
                    name="min_order_price"
                    label={lang("Min. Order Price")}
                    placeholder={lang("Enter Min. Order Price")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Min. Order Price"),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={!isEdit}
                      placeholder={lang("Enter Min. Order Price")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={24}>
                  <Form.Item
                    name="approx_delivery_time"
                    label={lang("Approximate preparation time(HH:MM)")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Approximate preparation time"),
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{2}:\d{2}$/.test(value) || value === "") {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              lang("Enter delivery time in HH:MM format")
                            )
                          );
                        },
                      },
                    ]}
                  >
                    <Input disabled={!isEdit} placeholder="01:30" />
                    {/* <InputNumber disabled={!isEdit} placeholder="00:30 mins" /> */}
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="tax"
                    label={lang("Store Tax %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Store Tax Percentage"),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={!isEdit}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      min={0}
                      max={100}
                      placeholder={lang("Enter Tax %")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="commission_rate"
                    label={lang("Cash Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Cash Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={!isEdit}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="online_commission_rate"
                    label={lang("Online Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Online Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={lang("Auto Accept Orders")}
                    name="auto_accept_order"
                    className="auto_accept_order oreder-bar-check "
                    valuePropName="checked"
                  >
                    <Switch disabled={!isEdit} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className="">
              <Col span={24} md={24} className="mt-5">
                <Form.Item
                  disabled={!isEdit}
                  label={lang("Location (Drag Marker for Selecting Location)")}
                  name="location_on_map"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the location!"),
                    },
                  ]}
                >
                  <LocationMap
                    className="mt-3"
                    onChange={handleLocationChange}
                    userData={location}
                    editLocation={editLocation}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 justify-content-end mt-5">
              {isEdit ? (
                <>
                  <Button
                    onClick={hide}
                    className=" btnStyle btnOutlineDelete "
                  >
                    {lang("Cancel")}
                  </Button>
                  <Button
                    loading={loading}
                    className="primary_btn btnStyle "
                    htmlType="submit"
                  >
                    {lang("SUBMIT")}
                  </Button>
                </>
              ) : (
                <Button onClick={hide} className="primary_btn btnStyle ">
                  {lang("Okay")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ViewAndEditModal;
