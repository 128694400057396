import React, { useEffect, useState } from 'react';
import useRequest from "../hooks/useRequest";
const PostAndRedirectButton = () => {

    const { request } = useRequest();
    const [data, setData] = useState({})

    useEffect(() => {
        request({
            url: `/payment-link`,
            method: "GET",
            onSuccess: (res) => {
                const { data, area } = res;
                console.log(data, "Payment Info");
                setData(data);
            },
        });

    }, [])

 

    return (
        <form action="https://testsecureacceptance.cybersource.com/pay" method="post">
        {Object.entries(data).map(([key, value]) => (
          <input
            key={key}
            type="hidden"
            id={key}
            name={key}
            value={value}

          />
        ))}
        <input type="submit" value="Submit" />
      </form>
    );
};

export default PostAndRedirectButton;
