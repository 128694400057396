
import {
    Card,
    Col,
    Row,
} from "antd";

import React from "react";
import PostAndRedirectButton from "../../components/Payment";


function PaymentPage() {
    return (
        <>
            <div className="tabled quoteManagement">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card bordered={false} className="criclebox tablespace mb-24">
                            <PostAndRedirectButton />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}


export default PaymentPage;
