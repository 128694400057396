import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  TimePicker,
  Space
} from "antd";
import React, { useEffect, useState } from "react";
import { MinusCircleOutlined } from '@ant-design/icons'
import {
  SelectInput,
  TextInputBox
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { Palestine } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";

const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const AddForm = ({ section, show, hide, data, refresh }) => {
  const api = {
    restaurant: apiPath.company,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllRestaurants, setIsAllRestaurants] = useState(false);
  const [areas, setAreas] = useState([]);
  const [document, setDocument] = useState();
  // const [isPrimary, setIsPrimary] = useState(false);
  const [logo, setLogo] = useState();
  const { country, cities } = useAppContext();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const { language } = useAppContext();

  const getRestaurants = (cities) => {
    request({
      url: `/restaurants/` + country.country_id,
      method: "POST",
      data: { cities: cities },
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurants(data);
        }
      },
    });
  };

  useEffect(() => {
    //  getCountry();
    // getCities();
    // getCategory();
    // getFoodCategory();
  }, []);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const getAreas = (id) => {
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          // const ar_area = data.map(item => ({ ...item, name: item.ar_name }));
          setAreas(data);
        }
      },
    });
  };


  useEffect(() => {
    if (!country.country_id) return;

  }, [country.country_id]);

  useEffect(() => {
    if (!data) return;
    console.log(data);


    console.log("data.auto_accept_order", data.auto_accept_order);

    form.setFieldsValue({ ...data });
    setFile([data.image]);
  }, [data]);

  const onCreate = (values) => {
    console.log(values, "hfjhdkghkhdgkd");

    const { location, time } = values;
    let payload = {
      ...values,
      open_time: time[0],
      close_time: time[1],
      logo: logo,
      country_id: country.country_id,
      ...location,
    };

    console.log(payload, "payload");
    setLoading(true);
    request({
      url: `${data ? api.restaurant + "/" + data._id : api.restaurant}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
          setLoading(false);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLogo = (data) => {
    setLogo(data[0]?.url);
  };



  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const dummyDeliveryTypes = [
    { name: "Delivery", ar_name: "توصيل", _id: "Delivery" },
    { name: "Pickup", ar_name: "يلتقط", _id: "Pickup" },
  ];

  const handleLocationChange = (value) => { };

  return (
    <Modal
      open={show}
      width={1400}
      okText="Save"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      footer={null}
      className="tab_modal"
    >
      <Form
        initialValues={{
          have_whatsapp_sec: false, // handleDeliveryTypeChange(value);
          have_whatsapp: false,
          auto_accept_order: true,
          country_id: country.country_id,
          tax: 10,
          commission_rate: 20,
          online_commission_rate: 23,
          break_slots: [{ slot_time: undefined }]
        }}
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
      >
        <h4 className="modal_title_cls">{`${data ? lang("Update") + " " + section : lang("Add") + " " + section
          }`}</h4>
        <Row gutter={[16, 16]}>
          <Col span={24} lg={14} xs={24} className="d-flex flex-column gap-3">
            <Card className="">
              <div className="row g-3">


                <div className="col-md-6">
                  <Form.Item
                    className="mb-0"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value !== undefined && value?.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Logo is required")),
                          );
                        },
                      },
                    ]}
                    label={lang("Upload Logo")}
                    name="logo1"
                    placeholder={lang("Upload Logo")}
                  >
                    <SingleImageUpload
                      value={logo}
                      fileType={FileType}
                      imageType={"logo"}
                      onChange={(data) => handleUploadLogo(data)}
                    />
                  </Form.Item>
                  <p
                    className="img-size-details"
                    style={{ fontSize: 11, marginTop: 4 }}
                  >
                    {lang(`**Logo should be 600x400 for best view in gallery image. You
                    can select only (.png, .jpeg, .jpg) format files upto 1 MB
                    file size. .!!!`)}
                  </p>
                </div>

              </div>
            </Card>

            <Card className="">
              <div className="row g-3">
                <TextInputBox
                  name="name"
                  label={lang("Company Name")}
                  placeholder={lang("Enter Company Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Company Name"),
                    },
                  ]}
                />

                <TextInputBox
                  name="ar_name"
                  label={lang("Company Name Arabic")}
                  placeholder={lang("Enter Company Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Company Name"),
                    },
                  ]}
                />

                <div className="col-xxl-6 col-lg-12 col-md-6">
                  <Form.Item
                    className="checkBox_wrap"
                    label={lang("Working Days")}
                    name="working_days"
                    rules={[{
                      required: true,
                      message: lang('Missing Working Days Selection!')
                    }]}
                  >
                    <Checkbox.Group
                      onChange={(value) => console.log(value, "working")}
                    >
                      {weekdays.map((item, idx) => (
                        <Checkbox value={item.name} key={idx}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12 col-md-6 px-0">
                  <Row gutter={20}>
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Working Time")}
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select working time"),
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          format="h:mm a"
                          placeholder={[lang("Open Time"), lang("Close Time")]}
                          use12Hours
                          order={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <SelectInput
                  name="city_id"
                  label={lang("City")}
                  placeholder={lang("Select City")}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities}
                  cover={{ md: 8 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                  handleChange={(value) => {
                    getAreas(value);
                    getRestaurants([value]);
                    form.setFieldsValue({ area: null });
                  }}
                />

                {country.country_id == Palestine.id && (
                  <SelectInput
                    name="area"
                    label={lang("Area")}
                    placeholder={lang("Select Area")}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={areas} //TODO:  change to area
                    cover={{ md: 12 }}
                    colProps={{ sm: 24, span: 24 }}
                    rules={[
                      {
                        required: country.country_id == Palestine.id,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  />
                )}

                <TextInputBox
                  name="address"
                  label={lang("Address")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
                <TextInputBox
                  name="ar_address"
                  label={lang("Address Arabic")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
              </Row>
            </Card>

            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <Col span={24} md={8}>
                  <Form.Item
                    label={lang("For All Vendors")}
                    name="for_all_restaurants"
                    initialValue={false}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={(e) => setIsAllRestaurants(e.target.checked)}></Checkbox>
                  </Form.Item>
                </Col>


                <SelectInput
                  allowClear
                  mode="multiple"
                  name="restaurants"
                  label={lang("Vendors")}
                  placeholder={lang("Select Vendors")}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  disabled={isAllRestaurants}
                  options={restaurants} //TODO:  change to area
                  cover={{ md: 12 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      required: !isAllRestaurants,
                      message: lang("Missing Vendors Selection"),
                    },
                  ]}
                />

              </Row>
            </Card>

            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <Col span={24}>
                  <Form.List label={lang('Break Slot')} name="break_slots">
                    {(fields, { add, remove }) => (
                      <Row gutter={[16, 16]} className="g-3">
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <Col span={14}>
                              <Form.Item
                                {...restField}
                                name={[name, 'slot_time']}
                                rules={[{
                                  required: true,
                                  message: 'Missing Slot Selection'
                                }]}
                              >
                                <TimePicker
                                  format="h:mm a"
                                  placeholder={`${lang("Slot Time")} ${index + 1}`}
                                  use12Hours
                                />
                              </Form.Item>


                            </Col>
                            {fields.length != 1 ?
                              <Col span={6}>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Col> : null}

                          </>
                        ))}
                        <Button type="dashed" onClick={() => add()} block>
                          Add Input
                        </Button>
                      </Row>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>

            {/* <Row gutter={20}>
              <Col md={12}>
                <Card>
                  <Row gutter={[0, 16]} className="g-3">
                    <TextInputBox
                      name="contact_person_name"
                      label={lang("Contact Person Name")}
                      placeholder={lang("Enter Contact Person Name")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          max: 20,
                          message: lang(
                            "Name should contain more then 20 characters!",
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter Person Name"),
                        },
                      ]}
                    />

                    <Col span={24} sm={24}>
                      <Form.Item
                        label={lang("Email ID")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: lang(
                              "Please enter a valid email address!",
                            ),
                          },
                          {
                            max: 50,
                            message: lang(
                              "Email address not more then 255 characters!",
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter email!"),
                          },
                        ]}
                      >
                        <Input placeholder={lang("Enter Email ID")} />
                      </Form.Item>
                    </Col>

                    <PhoneNumberField
                      label={lang("Contact Person Phone Number")}
                      name="mobile"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange}
                    />

                    <Form.Item name="have_whatsapp" valuePropName="checked">
                      <Checkbox className="" onChange={onChange}>
                        {lang("This number have WhatsApp to receive messages?")}{" "}
                      </Checkbox>
                    </Form.Item>

                    <PhoneNumberField
                      label={lang("Contact Person Phone Number")}
                      name="mobile2"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange2}
                      rules={false}
                    />

                    <Form.Item name="have_whatsapp_sec" valuePropName="checked">
                      <Checkbox className="" onChange={onChange}>
                        {lang("This number have WhatsApp to receive messages?")}{" "}
                      </Checkbox>
                    </Form.Item>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <Row gutter={[16, 16]} className="g-3">
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Create Password")}
                        name="password"
                        rules={[
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                            ),
                            message: lang(
                              "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter your password!"),
                          },
                        ]}
                      >
                        <Input.Password
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          placeholder={lang("Create Password")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Confirm Password")}
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: lang("Enter the confirm password!"),
                          },

                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  lang(
                                    "Password that you entered doesn't match!",
                                  ),
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={lang("Enter Confirm Password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row> */}
          </Col>
          <Col span={24} lg={10} xs={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="code"
                    label={lang("Company Code")}
                    placeholder={lang("Enter Company Code")}
                    cover={{ md: 10 }}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Company Code"),
                      },
                    ]}
                    normalize={(value) => value.toUpperCase()}
                  >
                    <Input placeholder={lang("Enter Company Code")} />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} span={24}>
                  <Form.Item
                    name="start_date"
                    label={lang("Code Start Date")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Start Date Selection"),
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="box_count"
                    label={lang("Number of Box")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Number of Box"),
                      },
                    ]}
                  >
                    <InputNumber
                      // formatter={(value) => `${value}%`}
                      // parser={(value) => value.replace("%", "")}
                      min={0}
                      max={100}
                      placeholder={lang("Enter Number of Box")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="delivery_fee"
                    label={`${lang("Delivery Fee for employee")} (${country.data.currency})`}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Delivery Fee for employee"),
                      },
                    ]}
                  >
                    <InputNumber
                      // formatter={(value) => `${value}%`}
                      // parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 "
                      min={0}
                    />
                  </Form.Item>
                </Col>

              </Row>

            </Card>

            <Row className="">
              <Col md={24} className="mt-5">
                <Form.Item
                  label={lang("Location (Drag Marker for Selecting Location)")}
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the location!"),
                    },
                  ]}
                >
                  <LocationMap
                    className="mt-3"
                    onChange={handleLocationChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 justify-content-end mt-5">
              <Button onClick={hide} className=" btnStyle btnOutlineDelete ">
                {lang("Cancel")}
              </Button>
              <Button loading={loading} className="primary_btn btnStyle " htmlType="submit">
                {lang("SUBMIT")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
