import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import apiPath from "../constants/apiPath";

const s3Config = {
  region: "ap-south-1",
  credentials: {
    accessKeyId: "B8ESG93XA59WD5N2H2SJ",
    secretAccessKey: "5HLMmSrAfEWuPByapd3lVMZBaynTNkfAKlTZZn0L",
  },
  endpoint: "https://fsn1.your-objectstorage.com",
  forcePathStyle: true,
};

const s3Client = new S3Client(s3Config);

// export const uploadToS3 = async ({ file, type = "images" }) => {
//   console.log(`Uploading ${type} file:`, file);
//   const key = "tawasi/" + type + `/${file.name}`;

//   const params = {
//     Bucket: "tawasi-s3",
//     Key: key,
//     Body: file,
//     ContentType: file.type,
//   };

//   const upload = new Upload({
//     client: s3Client,
//     params: params,
//   });

//   try {
//     const data = await upload.done();

//     const response = {
//       location: apiPath.assetURL + data.Key,
//     };
//     console.log(data, response);
//     return response;
//   } catch (err) {
//     throw err;
//   }
// };
export const uploadToS3 = async ({ file, type = "images" }) => {
  console.log(`Uploading ${type} file:`, file);

  try {
    // imageUpload
    const formData = new FormData();
    formData.append("image", file); // Appending file
    formData.append("type", type); // Appending file type

    const response = await fetch(`${apiPath.baseURL}${apiPath.imageUpload}`, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to upload image");
    }
    console.log("Image uploaded successfully2:", data);
    data.data.path = data?.data?.upload;
    return data?.data; // The uploaded image URL
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};
