// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-content table th{
    text-transform: uppercase;
}
.selected-flag{
    border-radius: 50px 0 0 50px !important;
}
.react-tel-input .flag-dropdown{
    border-radius: 50px 0 0 50px !important;
}
.flag-dropdown:hover{
    border: 1px solid #cacaca;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background:unset !important;
   
}
.react-tel-input .selected-flag{
    width: 45px !important;
}
.ant-modal-content .react-tel-input .form-control:focus{
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2) !important;
    border-right-width: 1px !important;
    outline: 0;
}
.ant-image{
    margin-right: 10px;
} 
.ant-image img{
    transition: 0.3s;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover .ant-image img{
    filter: grayscale(0%) !important;
}
.ant-menu-title-content .ant-image-mask{
    display: none !important;
}

.ant-menu-title-content img{
filter: brightness(100) !important;
}
/* .ant-menu-title-content .ant-image {
    min-width: 50px !important;
    
} */
/* .ant-menu-title-content a {
    min-width: 50px !important;
    
} */`, "",{"version":3,"sources":["webpack://./src/assets/styles/custom.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,2BAA2B;;AAE/B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,gCAAgC;IAChC,oDAAoD;IACpD,kCAAkC;IAClC,UAAU;AACd;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,wBAAwB;AAC5B;;AAEA;AACA,kCAAkC;AAClC;AACA;;;GAGG;AACH;;;GAGG","sourcesContent":[".ant-table-content table th{\n    text-transform: uppercase;\n}\n.selected-flag{\n    border-radius: 50px 0 0 50px !important;\n}\n.react-tel-input .flag-dropdown{\n    border-radius: 50px 0 0 50px !important;\n}\n.flag-dropdown:hover{\n    border: 1px solid #cacaca;\n}\n.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{\n    background:unset !important;\n   \n}\n.react-tel-input .selected-flag{\n    width: 45px !important;\n}\n.ant-modal-content .react-tel-input .form-control:focus{\n    border-color: #40a9ff !important;\n    box-shadow: 0 0 0 2px rgba(24,144,255,.2) !important;\n    border-right-width: 1px !important;\n    outline: 0;\n}\n.ant-image{\n    margin-right: 10px;\n} \n.ant-image img{\n    transition: 0.3s;\n}\n.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover .ant-image img{\n    filter: grayscale(0%) !important;\n}\n.ant-menu-title-content .ant-image-mask{\n    display: none !important;\n}\n\n.ant-menu-title-content img{\nfilter: brightness(100) !important;\n}\n/* .ant-menu-title-content .ant-image {\n    min-width: 50px !important;\n    \n} */\n/* .ant-menu-title-content a {\n    min-width: 50px !important;\n    \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
