import { Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { rolesOptions } from "../../../constants/var";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";

const AddForm = ({ setEmail, api, show, hide, selected, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [isCollector, setIsCollector] = useState(
    selected ? selected.is_collector : false,
  );
  const [country, setCountry] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [city, setCity] = useState([]);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const { cities, country: headerCountry } = useAppContext();

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const getCity = (id) => {
    request({
      // url: `/city/${id}`,
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  const onCreate = (values) => {
    const { permission } = values;
    const payload = {
      ...values,
      is_collector: isCollector,
      country_id: headerCountry.country_id,
    };

    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;

    if (!permission) {
      payload.permission = [];
    }

    console.log(payload, "payload");
    //return
    setLoading(true);

    request({
      url: `${selected ? api.subAdmin + "/" + selected._id : api.subAdmin}`,
      method: selected ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);

          if (selected) {
            refresh();
          } else {
            setEmail(payload.email);
          }
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (!selected) return;

    form.setFieldsValue({
      ...selected,
      country_id: selected.country_id?._id,
      city_id: selected.city_ids,
      mobile: selected.country_code
        ? selected.country_code + selected.mobile_number
        : "",
    });
    let box = [];

    if (selected.is_collector) {
      box.push("collector");
    }
    if (selected.permission.length) {
      box.push("role");
    }
    setSelectedBox(box);
    setIsCollector(selected.is_collector);
    getCity(selected.country_id?._id);
    setMobileNumber({
      mobile_number: selected.mobile_number,
      country_code: selected.country_code,
    });
  }, [selected]);

  return (
    <Modal
      width={780}
      open={show}
      okText={selected ? lang("Update") : lang("Create")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">
          {selected ? "Edit" : "Add"} Sub-Admin
        </h4>
        <Row gutter={[16, 0]} className="w-100">
          <Col span={24} sm={24}>
            <Form.Item
              label={lang("Name")}
              name="name"
              rules={[
                {
                  max: 20,
                  message: lang(
                    "Name should not contain more then 20 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
                {
                  required: true,
                  message: lang("Please enter name"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          {/* <Col span={24} sm={12}>
            <Form.Item
              label="Country"
              name="country_id"
              rules={[
                { required: true, message: "Please select the country!" },
              ]}
            >
              <Select
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                autoComplete="off" placeholder="Select Country" showSearch onChange={(value) => getCity(value)}>
                {
                  country.map(item => <Select.Option key={item._id} label={item.name} value={item._id}>{item.name} </Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={24} sm={12}>
            <Form.Item
              label={lang("City")}
              name="city_id"
              rules={[
                { required: true, message: lang("Please select the city!") },
              ]}
            >
              <Select
                className="restaurant-selected"
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select City")}
                showSearch
              >
                {cities.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {item.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Role Title")}
              name="title"
              rules={[
                {
                  max: 30,
                  message: lang(
                    "Title should not contain more then 20 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Title should contain at least 2 characters!"),
                },
                {
                  required: true,
                  message: lang("Please enter role title"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Role Title")}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12} className="flagMobileNumber">
            <Form.Item
              label={lang("Mobile Number")}
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject(lang("Please enter phone number"));
                    }
                    if (!/^\d{8,15}$/.test(value)) {
                      return Promise.reject(
                        lang("Phone number must be between 8 and 12 digits"),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  const countryCode = country.iso2;
                  const number = value.slice(country?.dialCode?.length)
                    if (countryCode === 'il') {
                      if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                      if (number && !/^972\d{9}$/.test(value))  return lang("Number must be 9 digits.");
                    }
                    if (countryCode === 'ps') {
                      if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                      if (number && !/^970\d{9}$/.test(value)) return lang("Number must be 9 digits");
                    }
              
                    if (!/^\d+$/.test(value)) {
                      return "Invalid value: " + value;
                    }
                    return true;
                }}
                country={"il"}
                preferredCountries={["ps", "il"]}
                // value={
                //   mobileNumber
                //     ? (mobileNumber.country_code
                //         ? mobileNumber.country_code
                //         : "+27") +
                //       (mobileNumber.mobile_number ? mobileNumber.mobile_number : null)
                //     : "+27"
                // }
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          {/* 
        <Radio.Group value={isCollector ? 'collector' :'role'} onChange={({ target }) => {
            if (target.value == 'role') {
              setIsCollector(false);
            } else {
              form.setFieldsValue({ permission: [] })
              setIsCollector(target.checked);
            }
          }}>
            <Col span={24} md={24}>
              <div className="assign_role">
                <Radio value="role">Assign Roles And Responsibility</Radio>
                {
                  // !isCollector &&
                  <Form.Item
                    // label="Assign Roles And Responsibility"
                    name="permission"
                    className="assign_role_checkbox"
                    rules={[
                      {
                        required: true,
                        message: 'Please assign roles and responsibility'
                      }
                    ]}
                  >
                    <Checkbox.Group>
                      {rolesOptions.map((item, idx) => (
                        <Checkbox value={item.name} key={item.name}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                }
              </div>
            </Col>

            <Col span={24} md={24}>
              <div className="assign_role">
                <Radio value="collector">Create A Collector</Radio>
              </div>
            </Col>
          </Radio.Group> 
      
      */}

          <Checkbox.Group
            value={selectedBox}
            onChange={(values) => {
              console.log(values, "valuesvalues");
              setSelectedBox(values);
              setIsCollector(values.includes("collector"));
              if (values.includes("role")) {
                form.setFieldsValue({
                  permission: rolesOptions.map(({ name }) => name),
                });
              } else {
                // form.setFieldsValue({
                //   permission: [],
                // });
              }
            }}
          >
            <Col span={24} md={24}>
              <div className="assign_role">
                <Checkbox value="role">
                  {lang("Assign Roles And Responsibility")}
                </Checkbox>
                {
                  // !isCollector &&
                  <Form.Item
                    name="permission"
                    className="assign_role_checkbox"
                    rules={[
                      {
                        required: false,
                        message: lang("Please assign roles and responsibility"),
                      },
                    ]}
                  >
                    <Checkbox.Group onChange={(value) => {
                      if (value.length != rolesOptions.length) {
                        const old = [...selectedBox]
                        let index = old.indexOf("role");
                        if (index !== -1) old.splice(index, 1);
                        setSelectedBox(old)
                      }
                    }}>
                      {rolesOptions.map((item, idx) => (
                        <Checkbox value={item.name} key={item.name}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                }
              </div>
            </Col>

            <Col span={24} md={24}>
              <div className="assign_role">
                <Checkbox value="collector">
                  {lang("Create A Collector")}
                </Checkbox>
              </div>
            </Col>
          </Checkbox.Group>

          <Col span={24} sm={24}>
            <Form.Item
              label="Email Id"
              name="email"
              rules={[
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 50,
                  message: lang(
                    "Email should not contain more then 50 characters!",
                  ),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
