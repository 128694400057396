export const s3Config = (type) => ({
  dirName: "tawasi/" + type,
  bucketName: "tawasi-s3",
  region: "ap-southeast-2",
  accessKeyId: "AKIA4MTWKBIIMBIQLJ67",
  secretAccessKey: "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",
});

// export const s3Config = (type) => ({
//   dirName: "tawasi/" + type,
//   bucketName: "inventcolabs",
//   region: "ap-south-1",
//   accessKeyId: "AKIAXTJNUUWFIPVPT4U7",
//   secretAccessKey: "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",
// });
