import Register from "./Auth/Register";
import SubAdmin from "./User/SubAdmin/Index";
import Restaurant from "./Restaurant/Index";
import Discount from "./Discount/Index";
import CmsManagement from "./CmsManagement/Index";
import DeliveryHistory from "./DeliveryHistory/Index";
import Ratings from "./Ratings/Index";
import Notifications from "./Notification/Index";
import Reports from "./Reports/Index";
import Collector from "./User/Collector/Index";
import Category from "./Category/Index";
import Order from "./Order/Index";
import Customer from "./User/Customer/Index";
import Company from "./Company/Index";

export {
  Register,
  SubAdmin,
  Restaurant,
  Discount,
  CmsManagement,
  DeliveryHistory,
  Ratings,
  Notifications,
  Reports,
  Collector,
  Category,
  Customer,
  Order,
  Company
};
