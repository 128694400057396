import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Input
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Currency from '../../../components/Currency'
import EditIcon from "../../../assets/images/edit.svg";
import deleteWhiteIcon from "../../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../../assets/images/plus.svg";
import ConfirmationBox from "../../../components/ConfirmationBox";
import DeleteModal from "../../../components/DeleteModal";
import SectionWrapper from "../../../components/SectionWrapper";
import ViewLogs from "../../../components/ViewLogs";
import apiPath from "../../../constants/apiPath";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../../constants/reasons";
import { Months } from "../../../constants/var";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddFrom from "./AddFrom";
import { ApproveStatus } from "../../Driver/_ChangeRequest";
import notfound from "../../../assets/images/not_found.png";
import { useParams } from "react-router";
import ChangeModal from "./_ChangeModal";
const { TabPane } = Tabs;

function Index() {
  const heading = lang("Customers") + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();

  const sectionName = lang("Customer");
  const routeName = "customer";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");

  const api = {
    status: apiPath.statusCustomer,
    addEdit: apiPath.addEditCustomer,
    list: apiPath.listCustomer,
    delete: apiPath.listCustomer,
  };

  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
    register: undefined
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onDelete = (id) => {
    request({
      url: api.delete + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, { name }) => {
        return name ? name : "-";
      },
    },
    {
      title: lang("Created by"),
      dataIndex: "dob",
      key: "dob",
      width: 200,
      render: (_, { added_by }) => {
        return added_by ? (added_by === 'vendor' ? lang("Driver Request") : lang('Customer App')) : lang('Customer App');
      },
    },
    {
      title: lang("Date Of Birth"),
      dataIndex: "dob",
      key: "dob",
      width: 200,
      render: (_, { dob }) => {
        return dob ? moment(dob).format("ll") : "-";
      },
    },
    {
      title: lang("Gender"),
      dataIndex: "gender",
      key: "gender",
      render: (_, { gender }) => {
        return gender
          ? gender == "F"
            ? "Female"
            : gender == "M"
              ? "Male"
              : "Others"
          : "-";
      },
    },
    {
      title: lang("Phone Number"),
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+965") +
          (mobile_number ? mobile_number : "")
        );
      },
    },
    {
      title: lang("Email ID"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? (
          <span style={{ textTransform: "lowercase" }}>{email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Register Date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    {
      title: lang("Loyalty Points"),
      dataIndex: "loyalty_points",
      key: "loyalty_points",
      render: (_, { total_loyalty_points }) => {
        return total_loyalty_points ? <span className="cap" >{total_loyalty_points}</span> : 0;
      },
    },
    {
      title: lang("Cash"),
      dataIndex: "cash_in_return",
      key: "cash_in_return",
      render: (_, { cash_in_return }) => {
        return cash_in_return ? <span className="cap"><Currency price={cash_in_return}/></span> : 0;
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            {record.is_active ? (
              <>
                <Tooltip title={"Edit"} color={"purple"} key={"edit"}>
                  <Button
                    className="edit-cls btnStyle primary_btn"
                    onClick={() => {
                      setSelected(record);
                      setVisible(true);
                    }}
                  >
                    <img src={EditIcon} />
                    <span>{lang("Edit")}</span>
                  </Button>
                </Tooltip>

                <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
                  <Button
                    title="Delete"
                    className="btnStyle deleteDangerbtn"
                    onClick={() => {
                      setSelected(record);
                      setShowDelete(true);
                    }}
                  >
                    <img src={deleteWhiteIcon} />
                    <span>{lang("Delete")}</span>
                  </Button>
                </Tooltip>

                <Tooltip title={"Block"} color={"purple"} key={"Block"}>
                  <Button
                    title="Block"
                    className="block-cls cap"
                    onClick={() => {
                      setSelected(record);
                      setShowStatus(true);
                    }}
                  >
                    {lang("Block")}
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Tooltip title={"UnBlock"} color={"purple"} key={"UnBlock"}>
                <Button
                  title="UnBlock"
                  className="block-cls cap"
                  onClick={() => {
                    setSelected(record);
                    setShowStatus(true);
                  }}
                >
                  {lang("UnBlock")}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={lang("View")}
              color={"purple"}
              key={"View" + routeName}
            >
              <Button
                title="View"
                className="btnStyle btnOutlineDelete"
                onClick={() => {
                  setSelected(record);
                  showLogs(true);
                }}
              >
                {lang("View")}
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [refresh, debouncedSearchText, filter, country?.country_id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}${path ? `&status=1` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const handleTabChange = (status) => {
    setSelectedTab(status);
    // fetchData(pagination, '', status)
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Customers LIST")} key="all">
                  <SectionWrapper
                    cardHeading={lang("Customers") + " " + lang("List")}
                    extra={
                      <>
                        <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                          <div className="pageHeadingSearch">
                            <Input.Search
                              className="searchInput"
                              placeholder={lang(
                                "Search by vendor name, Phone number, email ,address",
                              )}
                              onChange={onSearch}
                              allowClear
                            />
                          </div>
                          <div className="role-wrap">
                            <Select

                              width="110px"
                              placeholder={lang("City")}
                              showSearch
                              value={filter.city_id}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={cities.map((item) => ({
                                value: item._id,
                                label: item.name,
                              }))}
                              onChange={(value) => onChange("city_id", value)}
                            />
                          </div>
                          <div className="role-wrap">
                            <Select
                              width="110px"
                              placeholder={lang("Year")}
                              showSearch
                              value={filter.year}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={years.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(value) => onChange("year", value)}
                            />
                          </div>
                          <div className="role-wrap">
                            <Select
                              width="110px"
                              placeholder={lang("Month")}
                              showSearch
                              value={filter.month}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={months.map((item) => ({ ...item }))}
                              onChange={(value) => onChange("month", value)}
                            />
                          </div>

                          <div className="role-wrap">
                            <Select
                              width="110px"
                              placeholder={lang("Created by")}
                              showSearch
                              value={filter.register}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={[{
                                value: 1,
                                label: 'Driver Request',
                              }, {
                                value: 2,
                                label: 'Customer App',
                              }]}
                              onChange={(value) => onChange("register", value)}
                            />
                          </div>

                          <Button
                            onClick={() =>
                              setFilter({
                                country_id: undefined,
                                city_id: undefined,
                                year: undefined,
                                month: undefined,
                                register: undefined
                              })
                            }
                            type="primary"
                            icon={<UndoOutlined />}
                          >
                            {lang("Reset")}
                          </Button>
                          <Button
                            className="primary_btn btnStyle"
                            onClick={(e) => {
                              setVisible(true);
                              setSearchText("");
                            }}
                          >
                            <span className="add-Ic">
                              <img src={Plus} />
                            </span>
                            {lang("Add")} {sectionName}
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          // showSizeChanger: true,
                          // showQuickJumper: true,
                          // pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={handleChange}
                        className="ant-border-space"
                      />
                    </div>
                  </SectionWrapper>
                </TabPane>

                <TabPane tab={lang("CHANGE REQUEST")} key={`CHANGE_REQUEST`}>
                  <Request />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete User")}
          subtitle={lang(`Are you sure you want to Delete this user?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          reasons={DeleteCustomerReasons}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
            } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${selected?.is_active ? lang(`block`) : lang(`unblock`)
            } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={selected?.is_active ? BlockCustomerReasons : []}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}
    </>
  );
}

const Request = () => {
  const { country } = useContext(AppStateContext);

  const params = useParams();
  const api = {
    list: apiPath.request,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  const [selectedDriver, setSelectedDriver] = useState();

  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const getFilter = () => {
    request({
      url: `${api.list}/filters/Customer`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const approvalColumns = [
    {
      title: lang("Customer ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { user_id }) =>
        user_id ? <span className="cap">#{user_id.uid}</span> : "-",
    },
    {
      title: lang("Customer name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { user_id }) => (
        <>
          <Image
            width={40}
            height={40}
            src={user_id.image ? user_id.image : notfound}
            className="table-img "
          />
          {user_id.name ? <span className="cap">{user_id.name}</span> : "-"}
        </>
      ),
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    {
      title: lang("Change"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type }) => {
        return type ? <span className="cap">{type}</span> : "-";
      },
    },

    {
      title: lang("New Value"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type, email, mobile_number, country_code, city_id }) => {
        return type ? (
          type == "Email" ? (
            <span className="cap">{email}</span>
          ) : type == "City" ? (
            <span className="cap">{city_id.name}</span>
          ) : type == "Mobile" ? (
            <span className="cap">
              {" "}
              +{country_code}-{mobile_number}
            </span>
          ) : (
            ""
          )
        ) : (
          "-"
        );
      },
    },

    {
      title: lang("Request date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record, { approve_status }) => {
        return (
          <>
            {record.status === ApproveStatus.PENDING ? (
              <Tooltip
                title={lang("Waiting for Approval")}
                color={"purple"}
                key={"Edit"}
              >
                <Button
                  title="Edit"
                  className="btnStyle btnOutlineDelete"
                  onClick={() => {
                    setSelectedDriver(record);
                    setVisible(true);
                  }}
                >
                  {lang("Waiting for Approval")}
                </Button>
              </Tooltip>
            ) : record.status === ApproveStatus.ACCEPT ? (
              <Tooltip
                title={lang("Approval Accepted")}
                color={"purple"}
                key="Approval Accepted"
              >
                <Button title="Edit" className="btnStyle btnOutlineDelete">
                  {lang("Accepted")}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={lang("Approval Rejected")}
                color={"purple"}
                key="Approval Rejected"
                onClick={() => {
                  setSelectedDriver(record);
                  setVisible(true);
                }}
              >
                <Button title="Edit" className="block-cls cap">
                  {lang("Rejected")}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        `${api.list}/Customer` +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Customer Change Request List`)}
        cardSubheading={""}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  //onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({
                    ...item,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={approvalColumns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>

        {visible && (
          <ChangeModal
            section={lang("Driver")}
            api={api}
            show={visible}
            hide={() => setVisible(false)}
            data={selectedDriver}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Index;
