import { Card, Col, Row, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { AppStateContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import OrderList from "./order";
import CompanyOrderList from "./company_order";

const { TabPane } = Tabs;

const notificationTabs = {
    Orders: lang("Ongoing Orders List"),
    CompanyOrders: lang("Ongoing Company Orders List"),
};

function Index() {
    const { setPageHeading } = useContext(AppStateContext);

    const { request } = useRequest();

    const [selectedTab, setSelectedTab] = useState(
        notificationTabs.Orders,
    );

    const handleTabChange = (status) => {
        setSelectedTab(status);
    };

    useEffect(() => {
        setPageHeading(lang("Order Management"));
    }, []);

    return (
        <>
            <div className="tabled quoteManagement">
                <Row gutter={[24, 0]}>
                    <Col span={24} md={24} xl={24}>
                        <Card bordered={false} className="criclebox tablespace mb-24">
                            <Tabs
                                className="main_tabs"
                                onTabClick={handleTabChange}
                                activeKey={selectedTab}
                                tabBarStyle={{ color: "green" }}
                            >
                                <TabPane
                                    tab={notificationTabs.Orders}
                                    key={notificationTabs.Orders}
                                >
                                    <div className="notification-main-wrap" style={{ padding: "16px" }}>
                                        <OrderList />
                                    </div>
                                </TabPane>

                                <TabPane
                                    tab={notificationTabs.CompanyOrders}
                                    key={notificationTabs.CompanyOrders}
                                >
                                    <CompanyOrderList />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Index;
