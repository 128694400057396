import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Input
} from "antd";
import moment from "moment";
import { UndoOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Currency  from '../../components/Currency'
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { BlockDriver, Last20Years, Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import DeleteModal from "../../components/DeleteModal";
import AddForm from "./AddForm";
import EditIcon from "../../assets/images/edit.svg";
import ViewDriverModal, { ApproveStatus } from "./_ViewDriverModal";
import ViewLogs from "../../components/ViewLogs";
import { BlockDriverReasons } from "../../constants/reasons";
import ChangeRequestModal from "./_ChangeRequest";

const { TabPane } = Tabs;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function Driver() {
  const heading = lang("Driver") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Driver";
  const routeName = "drivers";
  const params = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    status: apiPath.driver,
    addEdit: apiPath.driver,
    list: apiPath.driver,
    importFile: apiPath.driver + "/" + params.type,
  };

  
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [selected, setSelected] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const handleTabChange = (status) => {
    setSelectedTab(status);
    // fetchData(pagination, '', status)
  };

  const onDelete = (id) => {
    request({
      url: api.list + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("DRIVER ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { uid }) => (uid ? <span className="cap">{uid}</span> : "-"),
    },
    {
      title: lang("Driver name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { image, name }) => (
        <>
          <Image
            width={40}
            height={40}
            onError={(e) => {
              e.target.src = notfound;
            }}
            src={image ? image : notfound}
            className="table-img"
          />
          {name ? <span className="cap">{name}</span> : "-"}
        </>
      ),
    },
    // {
    //   title: lang("Country"),
    //   dataIndex: "country_id",
    //   key: "country_id",
    //   render: (_, { country_id }) => {
    //     return country_id ? (
    //       <span className="cap">{country_id.name}</span>
    //     ) : (
    //       "-"
    //     );
    //   },
    // }, 
    {
      title: lang("No. of vendors registered with"),
      key: "trip",
      dataIndex: "trip",
      render: (_, { restaurant_ids }) => {
        return restaurant_ids ? restaurant_ids.length : 0;
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id?.name}</span> : "-";
      },
    },
    {
      title: lang("Mobile Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+254") +
          (mobile_number ? mobile_number : "")
        );
      },
    },
    {
      title: lang("Status"),
      dataIndex: "is_available",
      key: "is_available",
      render: (_, { is_available, is_online }) => {
        if (!is_available) {
          return <span className="cap">Busy</span>;
        } else if (is_online) {
          return <span className="cap">Available</span>;
        } else {
          return <span className="cap">Offline</span>;
        }
      },
    },
    // {
    //   title: lang("Id card"),
    //   dataIndex: "id_document",
    //   key: "id_document",
    //   render: (_, { id_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={id_document ? id_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("RC"),
    //   dataIndex: "rc_document",
    //   key: "rc_document",
    //   render: (_, { rc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={rc_document ? rc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("car lin"),
    //   dataIndex: "car_lc_document",
    //   key: "car_lc_document",
    //   render: (_, { car_lc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={car_lc_document ? car_lc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("Driving"),
    //   dataIndex: "lc_document",
    //   key: "lc_document",
    //   render: (_, { lc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={lc_document ? lc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },

    {
      title: lang("Joining date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
   
    {
      title: lang("Trip"),
      key: "trip",
      dataIndex: "trip",
      render: (_, { trips }) => {
        return trips ? trips : 0;
      },
    },
    {
      title: lang("Wallet Balance"),
      key: "trip",
      dataIndex: "trip",
      render: (_, { wallet }) => {
        return wallet ?  <Currency  price={wallet.balance} />  : 0;
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <>
            {record.is_active ? (
              <>
                <Tooltip
                  title={lang("Edit")}
                  color={"purple"}
                  key={"Edit" + routeName}
                >
                  <Button
                    title="Edit"
                    className="edit-cls btnStyle primary_btn"
                    onClick={() => {
                      setSelected(record);
                      setVisible(true);
                    }}
                  >
                    <img src={EditIcon} />
                    <span>{lang("Edit")}</span>
                  </Button>
                </Tooltip>
                <Tooltip
                  title={lang("Suspend")}
                  color={"purple"}
                  key={"Edit" + routeName}
                >
                  <Button
                    title="Edit"
                    className="block-cls cap"
                    onClick={() => {
                      setSelected(record);
                      setShowStatus(true);
                    }}
                  >
                    {lang("Suspend")}
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Tooltip
                title={lang("Reactivate")}
                color={"purple"}
                key={"Edit" + routeName}
              >
                <Button
                  title="Edit"
                  className="block-cls cap"
                  onClick={() => {
                    setSelected(record);
                    setShowStatus(true);
                  }}
                >
                  {lang("Reactivate")}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={lang("View")}
              color={"purple"}
              key={"View" + routeName}
            >
              <Button
                title="View"
                className="btnStyle btnOutlineDelete"
                onClick={() => {
                  setSelected(record);
                  showLogs(true);
                }}
              >
                {lang("View")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    // {
    //   title: "Status",
    //   key: "is_active",
    //   dataIndex: "is_active",
    //   render: (_, { _id, is_active }) => {
    //     return (
    //       <Switch
    //         onChange={() => {
    //           handleChangeStatus(_id);
    //         }}
    //         defaultChecked
    //         checked={is_active}
    //       />
    //     );
    //   },
    // },
  ];

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""
        }${path ? `&status=${path}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
  }

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("DRIVER LIST")} key="all">
                  <SectionWrapper
                    cardHeading={lang(`Drivers List`)}
                    cardSubheading={""}
                    extra={
                      <>
                        {/* <div className="button_group justify-content-end w-100"> */}
                        <div className="w-100 text-head_right_cont">
                          {/* <div className="city-wrap">
                            <Select
                              width="110"
                              value={filter.country_id}
                              placeholder="Country"
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                getCities(value);
                                onChange("country_id", value);
                              }}
                              options={countries.map((item) => ({
                                value: item._id,
                                label: item.name,
                              }))}
                            />
                          </div> */}
                          <div className="pageHeadingSearch">
                            <Input.Search
                              className="searchInput"
                              placeholder={lang(
                                "Search by vendor name, Phone number, email ,address",
                              )}
                              onChange={onSearch}
                              allowClear
                            />
                          </div>
                          <div className="role-wrap">
                            <Select
                              last20Years
                              width="110px"
                              placeholder={lang("City")}
                              showSearch
                              value={filter.city_id}
                              // filterOption={false}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={cities.map((item) => ({
                                value: item._id,
                                label: item.name,
                              }))}
                              //  onPopupScroll={handleScroll}
                              // onSearch={(newValue) => setSearchCity(newValue)}
                              onChange={(value) => onChange("city_id", value)}
                            />
                          </div>
                          <div className="role-wrap">
                            <Select
                              width="110px"
                              placeholder={lang("Year")}
                              showSearch
                              value={filter.year}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={years.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(value) => onChange("year", value)}
                            />
                          </div>
                          <div className="role-wrap">
                            <Select
                              width="110px"
                              placeholder={lang("Month")}
                              showSearch
                              value={filter.month}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={months.map((item) => ({
                                ...item,
                              }))}
                              onChange={(value) => onChange("month", value)}
                            />
                          </div>

                          <Button
                            onClick={() =>
                              setFilter({
                                country_id: undefined,
                                city_id: undefined,
                                year: undefined,
                                month: undefined,
                              })
                            }
                            type="primary"
                            icon={<UndoOutlined />}
                          >
                            {lang("Reset")}
                          </Button>
                          <Button
                            className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                            onClick={(e) => {
                              setVisible(true);
                              setSearchText("");
                            }}
                          >
                            <span className="add-Ic">
                              <img src={Plus} />
                            </span>
                            {lang("Add")} {lang("Driver")}
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                          ///showSizeChanger: true,
                          // showQuickJumper: true,
                          // pageSizeOptions: ["10", "20", "30", "50"],
                        }}
                        onChange={handleChange}
                        className="ant-border-space"
                      />
                    </div>
                  </SectionWrapper>
                </TabPane>

                <TabPane
                  tab={lang("APPROVAL PENDING")}
                  key={QuoteStatus.REQUEST}
                >
                  <Approval />
                </TabPane>

                <TabPane tab={lang("CHANGE REQUEST")} key={`CHANGE_REQUEST`}>
                  <Request />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? lang(`Suspend`) : lang(`Reactive`)
            } ${lang("Drive")}`}
          subtitle={`${lang("Are you sure you want to")} ${selected?.is_active ? lang(`suspend`) : lang(`reactivate`)
            } ${lang("this user ?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          reasons={selected?.is_active ? BlockDriver : []}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}
    </>
  );
}

const Approval = () => {
  const { setPageHeading, country } = useContext(AppStateContext);

  const routeName = "drivers";
  const params = useParams();

  const api = {
    status: apiPath.driver,
    addEdit: apiPath.driver,
    list: apiPath.driver,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  const [selectedDriver, setSelectedDriver] = useState();

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  // useEffect(() => {
  //   getCountry();
  // }, []);

  // useEffect(() => {
  //   if (!country.country_id) return
  //   setCities([])
  //   getCities(country.country_id, 1);
  // }, [country.country_id]);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, page, search = false) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=200${search ? `&search=${search}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities((prev) => [...data]);
          //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
        }
      },
    });
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  // useEffect(() => {
  //   if (!country?.country_id) return
  //   getCities(country.country_id, page);
  // }, [page]);

  // useEffect(() => {
  //   if (!country?.country_id) return
  //   getCities(country.country_id, debouncedSearchCity, 1);
  // }, [debouncedSearchCity]);

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const approvalColumns = [
    {
      title: lang("DRIVER ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { uid }) => (uid ? <span className="cap">{uid}</span> : "-"),
    },
    {
      title: lang("Driver name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { image, name }) => (
        <>
          <Image
            width={40}
            height={40}
            src={image ? image : notfound}
            className="table-img "
            onError={(e) => {
              e.target.src = notfound;
            }}
          />
          {name ? <span className="cap">{name}</span> : "-"}
        </>
      ),
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    // {
    //   title: lang("Id card"),
    //   dataIndex: "id_document",
    //   key: "id_document",
    //   render: (_, { id_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={id_document ? id_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("RC"),
    //   dataIndex: "rc_document",
    //   key: "rc_document",
    //   render: (_, { rc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={rc_document ? rc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("car lin"),
    //   dataIndex: "car_lc_document",
    //   key: "car_lc_document",
    //   render: (_, { car_lc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={car_lc_document ? car_lc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    // {
    //   title: lang("Driving"),
    //   dataIndex: "lc_document",
    //   key: "lc_document",
    //   render: (_, { lc_document }) => (
    //     <Image
    //       onError={(e) => { e.target.src = notfound }}
    //       src={lc_document ? lc_document : notfound}
    //       className="table-img image-doc"
    //     />
    //   ),
    // },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+254") +
          (mobile_number ? mobile_number : "")
        );
      },
    },
    // {
    //   title: lang("Status"),
    //   dataIndex: "is_available",
    //   key: "is_available",
    //   render: (_, { is_available, is_online }) => {
    //     if (is_available && is_online) {
    //       return <span className="cap">Busy</span>;
    //     } else if (is_online) {
    //       return <span className="cap">Online</span>;
    //     } else {
    //       return <span className="cap">Offline</span>;
    //     }
    //   },
    // },
    {
      title: lang("Request date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record, { approve_status }) => {
        return (
          <>
            {record.approve_status === ApproveStatus.PENDING ? (
              <Tooltip
                title={lang("Waiting for Approval")}
                color={"purple"}
                key={"Edit" + routeName}
              >
                <Button
                  title="Edit"
                  className="btnStyle btnOutlineDelete"
                  onClick={() => {
                    setSelectedDriver(record);
                    setVisible(true);
                  }}
                >
                  {lang("Waiting for Approval")}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={lang("Approval Rejected")}
                color={"purple"}
                key="Approval Rejected"
                onClick={() => {
                  setSelectedDriver(record);
                  setVisible(true);
                }}
              >
                <Button title="Edit" className="block-cls cap">
                  {lang("Rejected")}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        "/pending" +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Drivers Approval Pending List`)}
        cardSubheading={""}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              {/* <div className="city-wrap">
                <Select
                  width="110"
                  value={filter.country_id}
                  placeholder="Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    getCities(value);
                    onChange("country_id", value);
                  }}
                  options={countries.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </div> */}
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  //onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({
                    ...item,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={approvalColumns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>

        {visible && (
          <ViewDriverModal
            section={lang("Driver")}
            api={api}
            show={visible}
            hide={() => setVisible(false)}
            data={selectedDriver}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </SectionWrapper>
    </>
  );
};

const Request = () => {
  const { setPageHeading, country } = useContext(AppStateContext);

  const routeName = "drivers";
  const params = useParams();

  const api = {
    list: apiPath.request,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  const [selectedDriver, setSelectedDriver] = useState();

  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters/Driver`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const approvalColumns = [
    {
      title: lang("DRIVER ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { user_id }) =>
        user_id ? <span className="cap">#{user_id.uid}</span> : "-",
    },
    {
      title: lang("Driver name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { user_id }) => (
        <>
          <Image
            width={40}
            height={40}
            src={user_id ? user_id.image : notfound}
            className="table-img "
          />
          {user_id ? <span className="cap">{user_id.name}</span> : "-"}
        </>
      ),
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    {
      title: lang("Change"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type }) => {
        return type ? <span className="cap">{type}</span> : "-";
      },
    },

    {
      title: lang("New Value"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type, email, mobile_number, country_code, city_id }) => {
        return type ? (
          type === "Email" ? (
            <span className="cap">{email}</span>
          ) : type === "City" ? (
            <span className="cap">{city_id.name}</span>
          ) : type === "Mobile" ? (
            <span className="cap">
              {" "}
              +{country_code}-{mobile_number}
            </span>
          ) : (
            ""
          )
        ) : (
          "-"
        );
      },
    },

    {
      title: lang("Request date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record, { approve_status }) => {
        return (
          <>
            {record.status === ApproveStatus.PENDING ? (
              <Tooltip
                title={lang("Waiting for Approval")}
                color={"purple"}
                key={"Edit" + routeName}
              >
                <Button
                  title={lang("Edit")}
                  className="btnStyle btnOutlineDelete"
                  onClick={() => {
                    setSelectedDriver(record);
                    setVisible(true);
                  }}
                >
                  {lang("Waiting for Approval")}
                </Button>
              </Tooltip>
            ) : record.status === ApproveStatus.ACCEPT ? (
              <Tooltip
                title={lang("Approval Accepted")}
                color={"purple"}
                key="Approval Accepted"
              >
                <Button
                  title={lang("Edit")}
                  className="btnStyle btnOutlineDelete"
                >
                  {lang("Accepted")}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={lang("Approval Rejected")}
                color={"purple"}
                key="Approval Rejected"
                onClick={() => {
                  setSelectedDriver(record);
                  setVisible(true);
                }}
              >
                <Button title={lang("Edit")} className="block-cls cap">
                  {lang("Rejected")}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        `${api.list}/Driver` +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Drivers Change Request List`)}
        cardSubheading={""}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  //onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({
                    ...item,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={approvalColumns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>

        {visible && (
          <ChangeRequestModal
            section={lang("Driver")}
            api={api}
            show={visible}
            hide={() => setVisible(false)}
            data={selectedDriver}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Driver;
