import { Button, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import EditIcon from "../../assets/images/edit.svg";
import SectionWrapper from "../../components/SectionWrapper";
import lang from "../../helper/langHelper";
import Plus from "../../assets/images/plus.svg";
import { AppStateContext } from "../../context/AppContext";

function FAQList() {
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setPageHeading ,country} = useContext(AppStateContext);


  useEffect(() => {
    // setPageHeading(lang("FAQ"));
    fetchFAQs();
  }, [country.country_id]);

  const fetchFAQs = () => {
    setLoading(true);
    request({
      url: "/admin/Faq",
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  const columns = [
    {
      title: lang("Question"),
      dataIndex: "question",
      key: "question",
    },
    {
      title: lang("Arabic Question"),
      dataIndex: "ar_question",
      key: "ar_question",
    },
    {
      title: lang("Action"),
      key: "action",
      render: (_, record) => (
        <Tooltip title="Edit"  className="edit-cls btnStyle primary_btn">
          <Button onClick={() => navigate(`/faqs/${record._id}/edit`)}>
          <img src={EditIcon} />
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <SectionWrapper
        cardHeading={lang("FAQ")}
        extra={
          <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  // setVisible(true);
                  // setSearchText("");
                  navigate('/faqs/new')
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span> 
                {lang("Add")} {lang("FAQ")}
              </Button>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            rowKey="_id"
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default FAQList;
